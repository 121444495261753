<template>
    <div class="modal fade dialogbox" id="cardMsg" tabindex="-1"
         role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-icon text-primary">
                    <ion-icon name="infinite-outline"></ion-icon>
                </div>
                <div class="modal-header">
                    <h5 class="modal-title">EarnRelai Card Eligibility</h5>
                </div>
                <div class="modal-body">
                    <p>
                        To be eligible to make use of the EarnRelai Card.
                    </p>
                    <p>
                        You should have deposited an equivalent of $150,000 into
                        your wallet
                    </p>

                </div>
                <div class="modal-footer">
                    <div class="btn-inline">
                        <a href="javascript:void(0)" class="btn" data-bs-dismiss="modal">Close</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'cardMessage',
};
</script>

<style scoped>

</style>