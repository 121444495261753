<template>
    <div class="modal fade dialogbox" id="autoTrade" data-bs-backdrop="static" tabindex="-1"
         role="dialog">
        <div class="modal-dialog modal-fullscreen-lg-down" role="document">
            <div class="modal-content">
                <div class="modal-icon text-success">
                    <ion-icon name="trending-up-outline"></ion-icon>
                </div>
                <div class="modal-header">
                    <h5 class="modal-title">EarnRelai Auto Stake</h5>
                </div>
                <div class="modal-body">
                    <p>
                        EarnRelai <b>Auto Stake</b> will run through the course of 100 days. <br>
                        Once activated withdrawals from the specified crypto asset will be disabled during this period.
                        <br>
                    </p>
                    <p>
                        Accumulated interest can be withdrawn from the stake wallet to the crypto wallet after the first 30 days.
                    </p>
<!--                    <small class="text-primary">-->
<!--                        Please note the system automatically transfers whatever interest is left after the elapsed period-->
<!--                        to the crypto wallet-->
<!--                    </small>-->
                    <hr>
                    <h5>Interest per available balance <small class="text-muted">(currency in USD)</small></h5>
                    <div class="row text-start" style="font-size: 12px">
                        <table class="table table-striped">
                            <tbody>
                            <tr>
                                <td>$1000.00 - $49,999.00</td>
                                <td>50%</td>
                            </tr>
                            <tr>
                                <td>$99,000.00 - $499,999.00</td>
                                <td>200%</td>
                            </tr>
                            <tr>
                                <td>$500,000.00 - $999,999.00</td>
                                <td>400%</td>
                            </tr>
                            <tr style="border-bottom: none">
                                <td style="border-bottom: none">$1M+</td>
                                <td style="border-bottom: none">800%</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                <div class="modal-footer">
                    <div class="btn-inline">
                        <template v-if="semaphore===0">
                            <a href="javascript:void(0)" class="btn">
                                <div class="spinner-grow text-primary" role="status"></div>
                            </a>
                        </template>
                        <template v-else>
                            <a href="javascript:void(0)" class="btn" data-bs-dismiss="modal">Close</a>
                            <a @click="enableTrade" href="javascript:void(0)" class="btn">
                                Proceed
                            </a>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import {mapGetters} from 'vuex'

export default {
    name: "autoTrade",
    data(){
        return {
            semaphore: 1,
            trade: [
                {
                    min_amt: 1,
                    max_amt: 499,
                    duration: 14,
                    e_percentage_val: 1, // div by 100
                    e_duration: 90
                },
                {
                    min_amt: 500,
                    max_amt: 9999,
                    duration: 20,
                    e_percentage_val: 1, // div by 100
                    e_duration: 150
                },
                {
                    min_amt: 10000,
                    max_amt: 49999,
                    duration: 30,
                    e_percentage_val: 2, // div by 100
                    e_duration: 150
                },
                {
                    min_amt: 50000,
                    max_amt: 250000,
                    duration: 60,
                    e_percentage_val: 2, // div by 100
                    e_duration: 200
                }
            ]
        }
    },
    props: {
        cryptoId: {
            type: String,
            required: true
        }
    },
    methods: {
        async enableTrade(trade){
            if(this.semaphore === 1){
                this.semaphore = 0;
                const {cryptoId} = this
                let user = await this.$store.dispatch('user/getUser', this.$store.getters['user/getUser'].id);
                if(user.status){
                    if(!user.data.data.assets[cryptoId]){
                        this.semaphore = 1;
                        return this.$store.dispatch('notification/display_noti', {
                            message: `Credit your ${cryptoId} asset to enable this feature`,
                            timeout: 1500
                        });
                    }else if(user.data.data.assets[cryptoId] <= 0){
                        this.semaphore = 1;
                        return this.$store.dispatch('notification/display_noti', {
                            message: `Credit your ${cryptoId} asset to enable this feature`,
                            timeout: 1500
                        });
                    }
                }
                else{
                    this.semaphore = 1;
                    return this.$store.dispatch('notification/display_noti', {
                        message: `An unknown error occurred!`,
                        timeout: 1500
                    });
                }
                return this.$store.dispatch('notification/display_noti', {
                    message: "Enable now? <br> Note that this action is irreversible!",
                    context: 'icon',
                    buttons: [
                        {
                            name: 'Yes proceed',
                            callback: async ()=>{
                                this.$store.dispatch('notification/closeNoti', 'noti-ios-buttons');
                                // this.$loader.show()
                                let response = await this.$store.dispatch('user/enableAutoTrade', {
                                    cryptoId,
                                    duration: trade.duration,
                                    e_duration: (trade.e_duration - trade.duration),
                                    e_percentage_val: trade.e_percentage_val
                                });
                                this.semaphore = 1;
                                if(response.status){
                                    $('#autoTrade').modal('hide');
                                    return this.$store.dispatch('notification/display_noti', {
                                        message: 'Operation successful!',
                                        timeout: 1500
                                    });
                                }else{
                                    return this.$store.dispatch('notification/display_noti', {
                                        message: response.message,
                                    });
                                }
                            }
                        },
                        {
                            name: 'Cancel',
                            callback: ()=>{
                                this.semaphore = 1;
                                this.$store.dispatch('notification/closeNoti', 'noti-ios-buttons')
                            }
                        }
                    ]
                });
            }

        }
    },
    mounted() {
        const tradeModal = $('#autoTrade');
        tradeModal.on('show.bs.modal', ()=>{
            this.semaphore = 1;
            // this.crypt_fiat_equiv = 0;
            // this.convCryptFiat();
        })
    }
}
</script>

<style scoped>

</style>