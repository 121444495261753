import { render, staticRenderFns } from "./passphraseInfo.vue?vue&type=template&id=20e25b91&scoped=true&"
import script from "./passphraseInfo.vue?vue&type=script&lang=js&"
export * from "./passphraseInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20e25b91",
  null
  
)

export default component.exports