<template>
    <!-- Send Action Sheet -->
    <div class="modal fade action-sheet" id="sendActionSheet2" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Send</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <form @submit.prevent="proceed" novalidate>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="account22">Crypto</label>
                                    <select class="form-control custom-select" id="account22" v-model="paymentObject.data.crypto">
                                        <template v-if="!user.data.assets">
                                            <option value="">you have no crypto asset</option>
                                        </template>
                                        <template v-if="Object.entries(user.data.assets).length <= 0">
                                            <option value="">you have no crypto asset</option>
                                        </template>
                                        <template v-else>
                                            <option :value="key" v-for="(value, key, idx) in user.data.assets" :key="idx">
                                                {{key}}
                                            </option>
                                        </template>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="text111">Receiving address</label>
                                    <input type="text" class="form-control" id="text111"
                                           v-model="paymentObject.data.address"
                                           :placeholder="`enter the ${paymentObject.data.crypto} address you are sending to`">
                                </div>
                            </div>

                            <div class="form-group basic">
                                <label class="label">
                                    Amount
                                    <span v-if="paymentObject.data" class="text-muted float-end">1 {{paymentObject.data.crypto}} = {{fiat_crypto_equiv}} {{paymentObject.data.currency}}</span>
                                </label>
                                <div class="input-group mb-2">
                                    <span class="input-group-text" id="basic-addon1">{{paymentObject.data.crypto}}</span>
                                    <input type="number" v-model.number="paymentObject.data.crypto_value" class="form-control" placeholder="Enter an amount">
                                </div>
                            </div>

                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg">Send</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * Send Action Sheet -->
</template>

<script>
import {mapGetters} from 'vuex'
import SendPayment from "../../../models/payments/sendPayment";
import Wallet from "../../../models/wallet";

export default {
    name: "sendAsset",
    data(){
        return {
            paymentObject: new SendPayment(),
            fiat_crypto_equiv: 0
        }
    },
    watch: {
      'paymentObject.data.crypto': function(newVal){
          setTimeout(()=>{
              Wallet.cryptoToFiat(
                  this.paymentObject.data.currency,
                  newVal
              ).then(response=>{
                  if(!isNaN(response.data.value)){
                      this.fiat_crypto_equiv = Number.parseFloat(response.data.value.toFixed(3))
                  }
              })
          }, 1000)
      }
    },
    computed: {
      ...mapGetters('user', {
          user: 'getUser'
      })
    },
    methods: {
        async proceed(){
            if(this.paymentObject.data.crypto && this.paymentObject.data.address && this.paymentObject.data.crypto_value){
                this.$loader.show();
                let response = await this.$store.dispatch('user/getUser', this.$store.getters['user/getUser'].id);
                if(response.status){
                    const $user = response.data;
                    if(this.paymentObject.data.crypto_value > $user.data.assets[this.paymentObject.data.crypto]){
                        this.$store.dispatch('notification/display_noti', {
                            message: 'Insufficient crypto balance',
                        });
                        return this.$loader.hide()
                    }
                    // if($user.data.auto_trade_assets){
                    //     // eslint-disable-next-line no-prototype-builtins
                    //     if($user.data.auto_trade_assets.hasOwnProperty(this.paymentObject.data.crypto)){
                    //         if($user.data.auto_trade_assets[this.paymentObject.data.crypto].enabled){
                    //             this.$store.dispatch('notification/display_noti', {
                    //                 message: 'Withdrawals have been disabled for this asset until its auto trading has been completed',
                    //             });
                    //             return this.$loader.hide()
                    //         }
                    //     }
                    // }

                    response = await this.$store.dispatch('wallet/sendPayment', this.paymentObject);
                    if(response.status){
                        $('#sendActionSheet').modal('hide');
                        this.$store.dispatch('notification/display_noti', {
                            message: 'Your request is being processed',
                            timeout: 1500
                        });
                        return this.$router.push({name: 'Transactions', query: {context: 'sent'}})
                    }else{
                        this.$store.dispatch('notification/display_noti', {
                            message: response.message,
                        });
                    }
                    return this.$loader.hide()
                }else{
                    this.$store.dispatch('notification/display_noti', {
                        message: 'We are unable to complete your request',
                    });
                }
            }
        }
    },
    mounted() {
        const modal = $('#sendActionSheet2');
        this.paymentObject.data.currency = this.$store.getters['user/getUser'].data.currency;
        this.paymentObject.data.crypto_value = 1;
        modal.on('hide.bs.modal', ()=>{
            this.paymentObject = new SendPayment();
            this.paymentObject.data.currency = this.$store.getters['user/getUser'].data.currency;
            this.paymentObject.data.crypto_value = 1;
        });
        modal.on('show.bs.modal', ()=>{
            if(this.user.data.assets[window.sessionStorage.getItem('cryptoContext')]){
                this.paymentObject.data.crypto = window.sessionStorage.getItem('cryptoContext');
            }
        })
    }
}
</script>

<style scoped>

</style>