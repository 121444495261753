<template>
    <div class="modal fade dialogbox" id="passphraseInfo" data-bs-backdrop="static" tabindex="-1"
         role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-icon text-success">
                    <ion-icon name="lock-closed-outline"></ion-icon>
                </div>
                <div class="modal-header">
                    <h5 class="modal-title" v-if="loading">
                        <i>fetching recovery phrase...</i>
                    </h5>
                    <h5 class="modal-title" v-else>Your recovery phrase</h5>
                </div>
                <div class="modal-body">
                    <template v-if="loading">
                        <div class="spinner-grow text-primary mt-4" role="status"></div>
                    </template>
                    <template v-else-if="error">
                        <h5 class="text-danger">{{message}}</h5>
                        <p>
                            Kindly navigate to your account settings page to recover your passphrase which
                            will be sent to the email address registered with this wallet
                        </p>
                        <button class="btn mt-2" @click.prevent="initPassPhrase">
                            <ion-icon name="reload-circle-outline"></ion-icon> Try again
                        </button>
                    </template>
                    <template v-else>
                        <h5 class="text-primary">
                            Write down or copy these words in the right order and save them somewhere safe
                        </h5>
                        <p class="mt-4">
                            <span class="badge badge-pill badge-secondary" v-for="(phrase, idx) in phrases" :key="idx">
                                {{phrase}}
                            </span>
                        </p>
<!--                        <p>Welcome 👍</p>-->
                    </template>
                </div>
                <div class="modal-footer">
                    <div class="btn-inline" v-if="!loading&&!error">
                        <a @click="copyPassPhrase" href="javascript:void(0)" class="btn btn-success">Copy</a>
                        <a href="javascript:void(0)" class="btn" data-bs-dismiss="modal">Continue</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'passPhraseInfo',
    data(){
        return {
            loading: true,
            message: "",
            error: false,
            phrases: []
        }
    },
    methods: {
        copyPassPhrase(){
            navigator.clipboard.writeText(this.phrases.join(','));
            return this.$store.dispatch('notification/display_noti', {
                message: 'copied to clipboard!',
                timeout: 1000
            })
        },
        async initPassPhrase(){
            this.loading = true;
            this.phrases = this.$globalFunc.passPhrase;
            const response = await this.$store.dispatch('user/updateUser', {
                userId: this.$store.getters['user/getUser'].id,
                userData: {pass_phrase: this.phrases}
            });
            if(!response.status){
                this.error = true;
                this.message = "Unable to fetch wallet passphrase"
            }else{
                this.error = false;
            }
            this.loading = false;
        }
    },
    mounted() {
        const modal = $('#passphraseInfo');
        modal.on('hidden.bs.modal', ()=>{
            this.$emit('closed')
        });
        modal.on('shown.bs.modal', ()=>{
            this.initPassPhrase();
        })
    }
};
</script>

<style scoped>

</style>