<template>
    <div>
        <template v-if="loading">
            <div class="container">
                <div class="col-12 bg-white text-center p-4">
                    <div class="spinner-grow text-primary" role="status"></div>
                </div>
            </div>
        </template>
        <template v-else>
            <template v-if="is_qualified">
                <div v-if="cards.length > 0" class="carousel-single splide">
                    <div class="splide__track">
                        <ul class="splide__list">
                            <li class="splide__slide px-2" v-for="(card, idx) in cards" :key="'card_'+idx">
                                <!-- card block -->
                                <div class="card-block main-card" :style="{backgroundImage: 'url('+cardBG2+')'}">
                                    <div class="card-main">
                                        <!--                                        <div class="card-button dropdown">-->
                                        <!--                                            <button type="button" class="btn btn-link btn-icon" data-bs-toggle="dropdown">-->
                                        <!--                                                <ion-icon name="ellipsis-horizontal"></ion-icon>-->
                                        <!--                                            </button>-->
                                        <!--                                            <div class="dropdown-menu dropdown-menu-end">-->
                                        <!--                                                <a class="dropdown-item" href="javascript:void(0)">-->
                                        <!--                                                    <ion-icon name="pencil-outline"></ion-icon>Edit-->
                                        <!--                                                </a>-->
                                        <!--                                                <a class="dropdown-item" href="javascript:void(0)">-->
                                        <!--                                                    <ion-icon name="close-outline"></ion-icon>Remove-->
                                        <!--                                                </a>-->
                                        <!--                                                <a class="dropdown-item" href="javascript:void(0)">-->
                                        <!--                                                    <ion-icon name="arrow-up-circle-outline"></ion-icon>Upgrade-->
                                        <!--                                                </a>-->
                                        <!--                                            </div>-->
                                        <!--                                        </div>-->
<!--                                        <div class="balance">-->
<!--                                            <span class="label">BALANCE</span>-->
<!--                                            <h1 class="title">-->
<!--                                                {{getUserFiatSymbol}}-->
<!--                                                <span v-if="getTotalFiatBalance<=0">{{Math.floor(getProfile.data.balance)}}</span>-->
<!--                                                <span v-else>{{Math.floor(getTotalFiatBalance)}}</span>-->
<!--                                            </h1>-->
<!--                                        </div>-->
                                        <div class="in">
                                            <div class="card-number ms-3">
                                                <span class="label text-end">Card Number</span>
                                                <span class="">
                                                    {{card.data.assignedNumber}}
                                                </span>
                                            </div>
                                            <div class="bottom mt-1 ms-3">
                                                <div class="card-expiry">
                                                    <span class="label">Expiry</span>
                                                    {{getReadableMonth(card.data.expireAt)>9?getReadableMonth(card.data.expireAt):'0'+getReadableMonth(card.data.expireAt)}} / {{getReadableYear(card.data.expireAt)}}
                                                </div>
                                                <div class="card-ccv">
                                                    <span class="label">CCV</span>
                                                    {{card.data.assignedCVV}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- * card block -->
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="container" v-else>
                    <div class="carousel-multiple splide">
                        <div class="splide__track">
                            <ul class="splide__list">
                                <li class="splide__slide card-p">
                                    <div class="bill-box card-bg bg-trans" :style="{backgroundImage: 'url('+cardBG+')'}">
                                        <div class="card-a">
                                            <p class="txt txt2 mt-4">
                                                If you have submitted an application for a earnrelai card, we will get back to you
                                                between 2 to 15 business working days through DHL to the address on your
                                                profile.
                                            </p>

                                            <button class="btn btn-outline-success btn-sm card-btn btn-2" data-bs-target="#cardApplicationForm" data-bs-toggle="modal">
                                                <ion-icon name="card-outline"></ion-icon> Apply Now
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

<!--                    <div class="col-12 bg-white text-center p-4">-->
<!--                        <ion-icon name="card-outline" class="fw-50"></ion-icon>-->
<!--                        <p class="alert alert-outline-primary">-->
<!--                            It seems there are no available cards for use at this moment.-->
<!--                        </p>-->
<!--                        <p class="mt-2">-->
<!--                            If you have submitted an application for one, we will get back to you-->
<!--                            between 2 to 15 business working days through DHL to the address on your-->
<!--                            profile.-->
<!--                        </p>-->
<!--                        <button class="btn btn-primary mt-4" data-bs-target="#cardApplicationForm" data-bs-toggle="modal">-->
<!--                            Apply Now-->
<!--                        </button>-->
<!--                    </div>-->
                </div>
            </template>
            <div class="container" v-else>
                <!-- carousel multiple -->
                <div class="carousel-multiple splide">
                    <div class="splide__track">
                        <ul class="splide__list">

                            <li class="splide__slide card-p">
                                <div class="bill-box card-bg" :style="{backgroundImage: 'url('+cardBG+')'}">
                                    <div class="card-a">
                                        <!--                                    <div class="img-wrapper">-->
                                        <!--                                        <img src="../assets/img/sample/brand/1.jpg" alt="img" class="image-block imaged w48">-->
                                        <!--                                    </div>-->
<!--                                        <div class="price">Metrics Wallet Cards</div>-->
                                        <p class="txt mt-3">
                                            Spend your crypto from anywhere in the world. Quickly convert, send and receive
                                            cash or shop online with your earnrelai card.
                                        </p>

                                        <button class="btn btn-outline-warning btn-sm card-btn" data-bs-target="#cardMsg" data-bs-toggle="modal" >
                                            YOU ARE NOT ELIGIBLE
                                        </button>
                                        <p data-bs-target="#cardMsg" data-bs-toggle="modal" class="text text-danger mt-1">find out why?</p>
                                    </div>
                                </div>
                            </li>

<!--                            <li class="splide__slide">-->
<!--                                <div class="bill-box">-->
<!--                                    <div class="img-wrapper">-->
<!--                                        <img src="../assets/img/sample/brand/2.jpg" alt="img" class="image-block imaged w48">-->
<!--                                    </div>-->
<!--                                    <div class="price">$ 9</div>-->
<!--                                    <p>Music Monthly Subscription</p>-->
<!--                                    <a href="#" class="btn btn-primary btn-block btn-sm">PAY NOW</a>-->
<!--                                </div>-->
<!--                            </li>-->

                        </ul>
                    </div>
                </div>
                <!-- * carousel multiple -->
                <!--                <div class="col-12 bg-white text-center p-4">-->
                <!--                    <ion-icon name="card-outline" class="fw-50"></ion-icon>-->
                <!--                    <p class="alert alert-outline-warning">-->
                <!--                        You are not eligible to use the MetricsWallet Card-->
                <!--                    </p>-->
                <!--                    <button class="btn btn-primary mt-4" disabled>Apply Now</button>-->
                <!--                </div>-->
            </div>
        </template>

        <card-application-form :is-qualified="is_qualified"/>
        <card-message v-if="!is_qualified"/>
    </div>
</template>

<script>
import cardApplicationForm from './modals/cardApplicationForm';
import cardMessage from './modals/cardMessage';
import methodsMixin from '../utils/methodsMixin';
import cardBG from "../assets/img/cards/card1.png"
// import cardBG1 from "../assets/img/cards/card2.png"
import cardBG2 from "../assets/img/cards/card3.png"

import {mapGetters} from 'vuex';

export default {
    name: "cards",
    data(){
        return {
            splide_options: {
                gap: 10,
                autoplay: true,
                arrows: false,
                padding: 10,
                pagination: false,
                rewind: true,
                clones: 2,
                fixedWidth: '100%',
                fixedHeight: '200px',
            },
            splide_options2: {
                gap: 10,
                autoplay: true,
                arrows: false,
                padding: 10,
                pagination: false,
                fixedWidth: '100%',
                fixedHeight: '200px',
                rewind: true
            },
            is_qualified: false,
            loading: false,
            cards: [],
            cardBG, cardBG2
        }
    },
    mixins: [methodsMixin],
    computed: {
        ...mapGetters('user', ['getUserFiatSymbol', 'getProfile']),
        ...mapGetters('wallet', ['getTotalFiatBalance'])
    },
    methods: {
        async initCards(){
            this.loading = true;
            let response = await this.$store.dispatch('user/getUserProfile', this.$store.getters['user/getUser'].id);
            if(response.status){
                if(response.data.data.cardEnabled){
                    this.is_qualified = true;
                    response = await this.$store.dispatch('card/fetchCards');
                    if(response.status){
                        this.cards = response.data;
                        setTimeout(()=>{
                            new Splide('.carousel-single', this.splide_options).mount();
                        }, 700)
                    }
                }
                setTimeout(()=>this.initCardCarousel(), 1000)
            }else{
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Unable to initialize cards!',
                })
            }
            this.loading = false
        },
        initCardCarousel(){
            new Splide('.carousel-multiple', this.splide_options2).mount();
        }
    },
    components: {
        cardApplicationForm,
        cardMessage
    },
    mounted() {
        this.initCards();
    }
}
</script>

<style scoped>
.card-bg{
    position: relative;
    height: 100%;
    background: transparent no-repeat center center;
    background-size: cover;
}
.card-bg:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.8);
    border-radius: 22px!important;
    z-index: 1;
}
.card-bg.bg-trans:before{
    background-color: rgba(0,0,0,.7);
}
.card-a{
    position: relative;
    z-index: 5;
    color: #ffffff;
}
.card-a p.txt{
    font-size: 0.9em;
    text-shadow: #000000 1px 1px 1px;
    font-weight: bold;
}
.card-a p.txt2{
    font-weight: normal;
}
.card-btn{
    margin-top: 40px
}
.card-btn.btn-2{
    margin-top: 50px
}
.main-card{
    background: #333333 no-repeat center center;
    background-size: cover;
}
.card-block .bottom{
    /*padding-top: 50px*/
}
.bill-box{
    border-radius: 20px;
}
</style>