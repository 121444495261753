<template>
    <!-- Exchange Action Sheet -->
    <div class="modal fade action-sheet" id="exchangeActionSheet" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Convert</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <form @submit.prevent="proceed" novalidate>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label" for="currency1">From</label>
                                            <select class="form-control custom-select" id="currency1" v-model="transactData.data.metadata.from">
                                                <template v-if="!user.data.assets">
                                                    <option value="">you have no crypto asset</option>
                                                </template>
                                                <template v-if="Object.entries(user.data.assets).length <= 0">
                                                    <option value="">you have no crypto asset</option>
                                                </template>
                                                <template v-else>
                                                    <option :value="key" v-for="(value, key, idx) in user.data.assets" :key="'from_'+idx">
                                                        {{key}}
                                                    </option>
                                                </template>
                                            </select>
                                            <span class="form-text text-muted" v-if="transactData.data.metadata.from">
                                                Available:  {{user.data.assets[transactData.data.metadata.from].toFixed(4)}} {{transactData.data.metadata.from}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label" for="currency2">To</label>
                                            <select class="form-control custom-select" id="currency2" v-model="transactData.data.metadata.to">
                                                <option :value="key" v-for="(value, key, idx) in $globalFunc.cryptoList" :key="idx">
                                                    {{value}} [{{key}}]
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group basic">
                                <label class="label">Enter Amount</label>
                                <div class="input-group mb-2">
                                    <span class="input-group-text text-primary" id="basic-addon2">
                                        <ion-icon name="swap-horizontal-outline"></ion-icon>
                                    </span>
                                    <input type="number" class="form-control" v-model.number="transactData.data.metadata.amount" placeholder="Enter an amount">
                                </div>
                            </div>



                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg">Exchange</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * Exchange Action Sheet -->
</template>

<script>
import Transaction from "../../../models/transaction";
import {mapGetters} from "vuex";

export default {
    name: "swapAsset",
    data(){
        return {
            transactData: new Transaction()
        }
    },
    computed: {
        ...mapGetters('user', {
            user: 'getUser'
        }),
    },
    methods: {
        async proceed(){
            if(this.transactData.data.metadata.from && this.transactData.data.metadata.to){
                if(!(this.transactData.data.metadata.from === this.transactData.data.metadata.to)){
                    if(typeof this.transactData.data.metadata.amount === 'number'){
                        if(!(this.transactData.data.metadata.amount <= 0)){
                            this.$loader.show();
                            let $user = await this.$store.dispatch('user/getUser', this.$store.getters['user/getUser'].id);
                            if($user.status){

                                // if($user.data.auto_trade_assets){
                                //     // eslint-disable-next-line no-prototype-builtins
                                //     if($user.data.auto_trade_assets.hasOwnProperty(this.paymentObject.data.crypto)){
                                //         if($user.data.auto_trade_assets[this.paymentObject.data.crypto].enabled){
                                //             this.$store.dispatch('notification/display_noti', {
                                //                 message: 'Withdrawals have been disabled for this asset until its auto trading has been completed',
                                //             });
                                //             return this.$loader.hide()
                                //         }
                                //     }
                                // }

                                const response = await this.$store.dispatch('wallet/convertAsset', this.transactData);
                                if(response.status){
                                    $('#exchangeActionSheet').modal('hide');
                                    this.$store.dispatch('notification/display_noti', {
                                        message: `You have successfully added ${response.data.value} to your ${this.transactData.data.metadata.to} wallet`,
                                    });
                                    this.$router.push({name: 'Transactions', query: {context: 'swap'}})
                                }else{
                                    this.$store.dispatch('notification/display_noti', {
                                        message: response.message,
                                    });
                                }
                            }else{
                                this.$store.dispatch('notification/display_noti', {
                                    message: "Unable to complete this operation",
                                });
                            }
                            this.$loader.hide();

                        }
                    }else{
                        return this.$store.dispatch('notification/display_noti', {
                            message: "Enter a valid amount",
                            timeout: 1500
                        });
                    }
                }else{
                    this.$store.dispatch('notification/display_noti', {
                        message: "You can not initiate a crypto conversion to the same crypto asset",
                        timeout: 2000
                    });
                }
            }

        }
    },
    mounted() {
        const swapModal = $('#exchangeActionSheet');
        swapModal.on('show.bs.modal', ()=>{
            this.transactData = new Transaction();
            if(this.user.data.assets){
                if(Object.entries(this.user.data.assets).length > 0){
                    for(const crypt of Object.entries(this.user.data.assets)){
                        if(crypt[0] !== this.transactData.data.metadata.to){
                            this.transactData.data.metadata.from = crypt[0];
                        }
                    }
                }
            }
        })
    }
}
</script>

<style scoped>

</style>