<template>
    <!-- Deposit Action Sheet -->
    <div class="modal fade action-sheet" id="depositActionSheet" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Deposit
                        <a class="float-end" href="javascript:void(0)" data-bs-dismiss="modal">
                            <ion-icon name="close"></ion-icon>
                        </a>
                    </h5>
                </div>
                <div class="modal-body">
                    <complete-deposit-asset v-bind="resultPayload" v-if="completed"/>
                    <div v-else class="action-sheet-content">
                        <form @submit.prevent="proceed">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="account1">Crypto</label>
                                    <select v-model="crypto_id" class="form-control custom-select" id="account1">
                                        <option :value="key" v-for="(value, key, idx) in $globalFunc.cryptoList" :key="idx">
                                            {{value}} ({{key}})
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group basic">
                                <label class="label">Amount (optional)</label>
                                <div class="input-group mb-2">
                                    <span class="input-group-text" id="basic-addona1">{{fiat_symbol}}</span>
                                    <input type="text" v-model.number="amount" class="form-control" placeholder="Enter an amount">
                                </div>
                            </div>


                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg">Deposit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * Deposit Action Sheet -->
</template>

<script>
import completeDepositAsset from "../../completeDepositAsset";

export default {
    name: "depositAsset",
    data(){
        return {
            amount: '',
            crypto_id: 'BTC',
            fiat_symbol: '',
            completed: false,
            resultPayload: {
                address: '',
                cryptoID: ''
            }
        }
    },
    methods: {
        async proceed(){
            if(typeof this.amount !== "number"){
                if(isNaN(Number.parseFloat(this.amount.length>0?this.amount:0))){
                    return
                }else{
                    this.amount = Number.parseFloat(this.amount.length>0?this.amount:0)
                }
            }
            if(typeof this.amount === 'number' && this.crypto_id){
                if(this.$globalFunc.cryptoExistInCoinbase(this.crypto_id)){
                    this.$loader.show();
                    const {amount, crypto_id} = this;
                    const response = await this.$store.dispatch('wallet/receivePayment', {
                        amount, crypto_id
                    });
                    this.$loader.hide()
                    if(response.status){
                        this.resultPayload.cryptoID = this.crypto_id;
                        this.resultPayload.address = response.data.address;
                        this.completed = true;
                    }else{
                        // console.log('Error Message:', response.message)
                        return this.$store.dispatch('notification/display_noti', {
                            message: 'An unknown error occurred',
                        });
                    }
                }else{
                    const {amount, crypto_id} = this;
                    this.$loader.show();
                    let response = await this.$store.dispatch('wallet/receivePaymentManual', {amount, crypto_id});
                    this.$loader.hide();
                    if(response.status){
                        this.resultPayload.cryptoID = this.crypto_id;
                        this.resultPayload.address = response.data.address;
                        this.completed = true;
                    }else{
                        console.log('Error Message:', response.message)
                        return this.$store.dispatch('notification/display_noti', {
                            message: 'An unknown error occurred',
                        });
                    }
                }
            }
        }
    },
    components: {
        completeDepositAsset
    },
    mounted() {
        this.fiat_symbol = this.$globalFunc.fiats[this.$store.getters['user/getUser'].data.currency].symbol_native;
        const depositModal = $('#depositActionSheet');
        depositModal.modal({
            backdrop: 'static'
        });
        depositModal.on('hidden.bs.modal', ()=>{
            this.completed = false;
            this.amount = "";
            this.crypto_id = "BTC";
            this.resultPayload.address = this.resultPayload.cryptoID = "";
        })
    }
}
</script>

<style scoped>

</style>