<template>
    <div>
        <div class="transactions">
            <!-- item -->
            <a @click="selected_asset.cryptoId=key" data-bs-target="#cryptoInfo" data-bs-toggle="modal" href="javascript:void(0)" class="item" v-for="(value, key, idx) in $globalFunc.cryptoList" :key="idx">
                <div class="detail">
                    <img class="crypto-img i-1x" v-if="key==='DOT'" src="../assets/img/icon/Polkadot_symbol_color.svg">
                    <img class="crypto-img i-1x" v-if="key==='SAFEMOON'" src="../assets/img/icon/safemoon_normal_dark.svg">
                    <img class="crypto-img i-1x" v-if="key==='KLV'" src="../assets/img/icon/klv.png">
                    <img class="crypto-img i-1x" v-if="key==='SHIB'" src="../assets/img/icon/shib-logo.svg">
                    <img class="crypto-img i-1x" v-if="key==='DAI'" src="../assets/img/icon/dai.png">
                    <img class="crypto-img i-1x" v-if="key==='USDC'" src="../assets/img/icon/usdc.png">
                    <img class="crypto-img i-1x" v-if="key==='CHZ'" src="../assets/img/icon/chz.png">
                    <img class="crypto-img i-1x" v-if="key==='MATIC'" src="../assets/img/icon/matic.png">
                    <img class="crypto-img i-1x" v-if="key==='UTK'" src="../assets/img/icon/utk.png">
                    <img class="crypto-img i-1x me-1" v-if="key==='AXS'" src="../assets/img/icon/axs.png">
                    <i v-else :class="`cc ${key} fa-3x`"></i>
                    <!--                            <img src="../assets/img/sample/brand/1.jpg" alt="img" class="image-block imaged w48">-->
                    <div>
                        <strong class="ms-1">{{ value }}</strong>
                        <p class="ms-1">
                            {{getUserFiatSymbol}}
                            <template v-if="$globalFunc.getObjInArray(assets_to_fiats_equiv, key).exist">
                                <span v-for="(value, idx) in assets_to_fiats_equiv" :key="'asset_'+idx">
                                    {{assets_to_fiats_equiv[idx][key]}}
                                </span>
                            </template>
                            <template v-else>0.00</template>
                        </p>
                    </div>
                </div>

                <div class="asset-center">
                    <template v-if="$globalFunc.getObjInArray(series_array, key).exist">
                        <apexchart width="70" height="30" type="line" :options="options" :series="$globalFunc.getObjInArray(series_array, key).value[key]"></apexchart>
                    </template>
                </div>

                <div class="right">
                    <div class="price text-primary">
                        <span v-if="getUser.data.assets">{{Number.parseFloat(getUser.data.assets[key]||'0').toFixed(4)||0}}</span>
                        <span v-else>0.00</span>&nbsp;<br>
                        <small class="text-info">
                            <template v-if="$globalFunc.getObjInArray(assets_to_fiats_equiv, key).exist && getUser.data.assets">
                                <span v-for="(value, idx) in assets_to_fiats_equiv" :key="'asset_'+idx">
                                    <template v-if="assets_to_fiats_equiv[idx][key]">
                                        {{getUserFiatSymbol}}{{Number.parseFloat((((assets_to_fiats_equiv[idx][key])*Number.parseFloat(getUser.data.assets[key]||'0')||0).toFixed(2))).toLocaleString()}}
                                    </template>
                                </span>
                            </template>
                            <template v-else>
                                {{getUserFiatSymbol}}0.00
                            </template>

<!--                            <template v-if="getUser.data.auto_trade_assets">-->
<!--                                <template v-if="getUser.data.auto_trade_assets[key]">-->
<!--                                    <ion-icon name="trending-up-outline"></ion-icon> {{Number(getUser.data.auto_trade_assets[key].amount).toFixed(2)}}-->
<!--                                </template>-->
<!--                                <template v-else>-->
<!--                                    <ion-icon name="trending-up-outline"></ion-icon> 0.00-->
<!--                                </template>-->
<!--                            </template>-->
<!--                            <template v-else>-->
<!--                                <ion-icon name="trending-up-outline"></ion-icon> 0.00-->
<!--                            </template>-->
                        </small>
                        <!--                        <span v-if="key==='AXS2'">AXS</span><span v-else>{{key}}</span>-->
                    </div>
                </div>
            </a>
            <!-- * item -->
        </div>
        <asset-details-ui v-bind="selected_asset"/>
        <deposit-asset-param/>
        <send-asset-param/>
        <auto-trade :crypto-id="selected_asset.cryptoId"/>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Wallet from "../models/wallet";
import assetDetailsUi from "./modals/assets/assetDetailsUi";
import depositAssetParam from "./modals/assets/depositAssetParam";
import sendAssetParam from "./modals/assets/sendAssetParam";
import autoTrade from './modals/trade/autoTrade';

export default {
    name: "assetListUI",
    data(){
        return {
            // assets_to_fiats_equiv: [],
            selected_asset: {
                cryptoId: '',
            },
            options: {
                chart: {
                    type: 'line',
                    width: 70,
                    height: 30,
                    sparkline: {
                        enabled: true
                    },
                },
                colors: [
                    '#1DCC70',
                    // '#6236FF'
                ],
                stroke: {
                    show: true,
                    curve: 'smooth',
                    // lineCap: 'butt',
                    // colors: undefined,
                    width: 2,
                    dashArray: 0,
                },
                tooltip: {
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            // eslint-disable-next-line no-unused-vars
                            formatter: function (seriesName) {
                                return ''
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                }
            },
            series: [{
                data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
            }],
            series_array: []
        }
    },
    methods: {
        populateSeriesChartData(){
            let cryptos = Object.entries(this.$globalFunc.cryptoList);
            cryptos.forEach((asset)=>{
                let asset_obj = {};
                asset_obj[asset[0]] = [{data: [0,0,0,0,0,0,0,0,0,0]}]
                this.series_array.push(asset_obj)
            });
        },
        async populateSeriesChartDataLive(){
            let cryptos = Object.entries(this.$globalFunc.cryptoList);
            // Load series chart data;
            let all_crypto_ids = cryptos.map((asset)=>asset[0]);
            let result = (await Wallet.cryptoToFiatAll(
                this.$store.getters['user/getUser'].data.currency,
                all_crypto_ids
            )).data.value;
            result.forEach((cryptoObjResult)=>{
                const currency = cryptoObjResult['currency'];
                const prices = cryptoObjResult['prices'];
                let asset_obj = {};
                asset_obj[currency] = [{
                    data: prices.map(value=>Number.parseFloat((value)))
                }]
                this.series_array.push(asset_obj);
            })
        },
        async convertUserAssetsToFiat(){
            let cryptos = Object
            .entries(this.$store.getters['user/getUser'].data.assets)
            .filter(crypt=>crypt[1] > 0);
            let all_crypto_ids = cryptos.map((asset)=>asset[0]);
            if(cryptos.length > 0){
                let result = (await Wallet.cryptoToFiatAll(
                    this.$store.getters['user/getUser'].data.currency,
                    all_crypto_ids
                )).data.value;
                if(result.length > 0){
                    let results = result.map((cryptoObjResult)=>{
                        const currency = cryptoObjResult['currency'];
                        const prices = cryptoObjResult['prices'];
                        let amount = 0;
                        cryptos.forEach(cryptObj=>{
                            if(cryptObj[0] === currency){
                                amount = Number.parseFloat(prices[prices.length-1]);
                                amount = amount * Number.parseFloat(cryptObj[1]);
                            }
                        });
                        return amount
                    });
                    results = results.reduce((x,y)=>x+y, 0);
                    this.$store.commit('wallet/setTotalFiatBalance', results)
                    if(results >= 150000){
                        const update_data = {};
                        if(!this.$store.getters['user/getProfile'].data.cardEnabled){
                            update_data.cardEnabled = true
                        }
                        if(results >= 250000){
                            if(!this.$store.getters['user/getProfile'].data.hwEnabled){
                                update_data.hwEnabled = true
                            }
                        }
                        await this.$store.dispatch('user/updateUserProfile', {
                            userId: this.$store.getters['user/getUser'].id,
                            userData: {...update_data}
                        })
                    }
                }else{
                    this.$store.dispatch('notification/display_noti', {
                        message: 'Unable to load resources, please try again!',
                        timeout: 1500
                    })
                }

            }
        },
        async convertCryptoAssetsToFiat(){
            let cryptos = Object.entries(this.$globalFunc.cryptoList);
            let all_crypto_ids = cryptos.map((asset)=>asset[0]);
            // let total_balance = 0;
            if(cryptos.length > 0){
                let result = (await Wallet.cryptoToFiatAll(
                    this.$store.getters['user/getUser'].data.currency,
                    all_crypto_ids
                )).data.value;
                if(result.length > 0){
                    // Load series chart data;
                    result.forEach((cryptoObjResult)=>{
                        const currency = cryptoObjResult['currency'];
                        const prices = cryptoObjResult['prices'];
                        let asset_obj = {};
                        asset_obj[currency] = [{
                            data: prices.map(value=>Number.parseFloat((value)))
                        }]
                        this.series_array.push(asset_obj);
                    });
                    let results = result.map((cryptoObjResult)=>{
                        const currency = cryptoObjResult['currency'];
                        const prices = cryptoObjResult['prices'];
                        let asset = {};
                        asset[currency] = (Number.parseFloat(prices[prices.length-1])).toFixed(4);
                        // total_balance += Number.parseFloat(asset[currency])
                        return asset
                    })
                    this.$store.commit('wallet/setCryptoAssets', results);
                }else{
                    this.$store.dispatch('notification/display_noti', {
                        message: 'Unable to fetch resources, please try again!',
                        timeout: 1500
                    })
                }
            }
            return this.convertUserAssetsToFiat()
        }
    },
    computed: {
        ...mapGetters('user', ['getUser', 'getUserFiatSymbol']),
        ...mapGetters('wallet', {
            assets_to_fiats_equiv: 'getCryptoAssets'
        })
    },
    components: {
        assetDetailsUi,
        depositAssetParam,
        sendAssetParam,
        autoTrade
    },
    mounted(){
        // setInterval(()=>{
        this.$store.dispatch('user/initUser', {
            user_id: this.$store.getters['user/getUser'].id,
            profile_id: this.$store.getters['user/getUser'].id
        })
        this.populateSeriesChartData()
        this.convertCryptoAssetsToFiat();
        // }, 10000)
    }

}
</script>

<style scoped>
.asset-center{
    /*background-color: red;*/
    width:70px;
    height:30px
}
</style>