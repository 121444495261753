<template>
    <div>
        <!-- App Capsule -->
        <div id="appCapsule">

            <!-- Wallet Card -->
            <div class="section wallet-card-section pt-1">
                <div class="wallet-card">
                    <!-- Balance -->
                    <div class="balance">
                        <div class="left">
                            <span class="title text-primary">
                                <ion-icon name="person-outline"></ion-icon> Hello {{getUser.data.name}}
                            </span>
                            <span class="title">Total Balance</span>
                            <h1 class="total">{{getUserFiatSymbol}}
<!--                                <span v-if="getTotalFiatBalance<=0">{{Math.floor(getProfile.data.balance)}}</span>-->
<!--                                <span v-else>{{Math.floor(getTotalFiatBalance)}}</span>-->
                                <span>{{Math.floor(getTotalFiatBalance).toLocaleString()}}</span>
                            </h1>
                        </div>
                        <div class="right">
                            <a href="javascript:void(0)" class="button" data-bs-toggle="modal" data-bs-target="#depositActionSheet">
                                <ion-icon name="add-outline"></ion-icon>
                            </a>
                        </div>
                    </div>
                    <!-- * Balance -->
                    <!-- Wallet Footer -->
                    <div class="wallet-footer">
                        <div class="item">
                            <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#depositActionSheet">
                                <div class="icon-wrapper bg-danger">
                                    <ion-icon name="arrow-down-outline"></ion-icon>
                                </div>
                                <strong>Deposit</strong>
                            </a>
                        </div>
                        <div class="item">
                            <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#sendActionSheet">
                                <div class="icon-wrapper">
                                    <ion-icon name="arrow-forward-outline"></ion-icon>
                                </div>
                                <strong>Send</strong>
                            </a>
                        </div>
                        <div class="item">
                            <router-link :to="{name:'HWToken'}">
                                <div class="icon-wrapper bg-success">
                                    <ion-icon name="hardware-chip-outline"></ion-icon>
                                </div>
                                <strong>HW Token</strong>
                            </router-link>
                        </div>
                        <div class="item">
                            <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#exchangeActionSheet">
                                <div class="icon-wrapper bg-warning">
                                    <ion-icon name="swap-vertical"></ion-icon>
                                </div>
                                <strong>Convert</strong>
                            </a>
                        </div>

                    </div>
                    <!-- * Wallet Footer -->
                </div>
            </div>
            <!-- Wallet Card -->

            <deposit-asset/>
            <!--            <withdraw-asset/>-->
            <send-asset/>
            <swap-asset/>

            <!-- Chart Widget -->
<!--            <div class="section mt-4">-->
<!--                <div style="height:560px; background-color: #FFFFFF; overflow:hidden; box-sizing: border-box; border: 1px solid #ffffff; border-radius: 4px; text-align: right; line-height:14px; font-size: 12px; font-feature-settings: normal; text-size-adjust: 100%; box-shadow: inset 0 -20px 0 0 #ffffff;padding:1px;padding: 0px; margin: 0px; width: 100%;">-->
<!--                    <div style="height:540px; padding:0px; margin:0px; width: 100%;">-->
<!--                        <iframe src="https://widget.coinlib.io/widget?type=chart&theme=light&coin_id=859&pref_coin_id=1505" width="100%" height="536px" scrolling="auto" marginwidth="0" marginheight="0" frameborder="0" border="0" style="border:0;margin:0;padding:0;line-height:14px;"></iframe>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;                    <div style="color: #FFFFFF; line-height: 14px; font-weight: 400; font-size: 11px; box-sizing: border-box; padding: 2px 6px; width: 100%; font-family: Verdana, Tahoma, Arial, sans-serif;">&ndash;&gt;-->
<!--                    &lt;!&ndash;                        <a href="https://coinlib.io" target="_blank" style="font-weight: 500; color: #FFFFFF; text-decoration:none; font-size:11px">Cryptocurrency Prices</a>&nbsp;by Coinlib&ndash;&gt;-->
<!--                    &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                </div>-->
<!--            </div>-->

            <!-- Assets -->
            <div class="section mt-4">
                <div class="section-heading">
                    <h2 class="title">Assets</h2>
                    <a href="javascript:void(0)" class="link">showing all</a>
                </div>
                <asset-list-ui/>
            </div>
            <!-- * Assets -->

          <div class="section mt-4">
            <div class="section-heading">
              <h2 class="title">Stocks</h2>
              <a href="javascript:void(0)" class="link">showing all</a>
            </div>
            <p class="text-center text-dark">
              Top five gainers, losers, and most active stocks, updated with current market activity for the most relevant results
            </p>
            <stock-list-widget/>

          </div>

            <!-- my cards -->
            <div class="section full mt-4 mb-3">
                <div class="section-heading padding">
                    <h2 class="title">EarnRelai Cards</h2>
                    <a href="javascript:void(0)" class="link">Showing all</a>
                </div>
                <cards/>
            </div>
            <!-- * my cards -->

            <!-- app footer -->
            <div class="appFooter">
                <div class="footer-title">
                    Copyright © EarnRelaiWallet {{(()=>new Date().getFullYear())()}}
                </div>
                All Rights Reserved.
            </div>
            <!-- * app footer -->

        </div>
        <!-- * App Capsule -->

        <two-fa @closed="$store.commit('auth/setTFAModal', false)" v-if="getTFAModal"/>
        <passphrase-info @closed="$store.commit('auth/setPassphraseModal', false)" v-if="getPassphraseModal"/>
    </div>
</template>

<script>
// import withdrawAsset from "../components/modals/withdrawAsset"
import sendAsset from "../components/modals/assets/sendAsset";
import swapAsset from "../components/modals/assets/swapAsset";
import depositAsset from "../components/modals/assets/depositAsset";
import assetListUI from "../components/assetListUI";
import cards from "../components/cards";
import twoFa from '../components/modals/twoFA';
import passphraseInfo from '../components/modals/passphraseInfo';

import {mapGetters} from 'vuex';
import StockListWidget from "../components/StockListWidget.vue";

export default {
    name: "Metrics",
    data(){
        return {
            splide_options1: {
                gap: 10,
                autoplay: true,
                arrows: false,
                padding: 10,
                pagination: false,
                fixedWidth: '100px',
                rewind: true
            },
            splide_options2: {
                gap: 10,
                autoplay: true,
                arrows: false,
                padding: 10,
                pagination: false,
                fixedWidth: '150px',
                rewind: true
            },

            passPhraseWaitInterval: null
        }
    },
    computed: {
        ...mapGetters('user', ['getUser', 'getUserFiatSymbol', 'getProfile']),
        ...mapGetters('wallet', ['getTotalFiatBalance']),
        ...mapGetters('auth', ['getTFAModal', 'getPassphraseModal'])
    },
    methods: {
        async fetchUnreadSupportMessges(){
            let response = await this.$store.dispatch('support/getUnreadMessages');
            if(response.status){
                this.$emit('msgloaded', response.data);
            }
        },
    },
    mounted() {
        this.fetchUnreadSupportMessges();
        if(this.getPassphraseModal){
            setTimeout(()=>{
                $('#passphraseInfo').modal('show');
            }, 2000)
        }
        if(this.getTFAModal){
            if(this.getPassphraseModal){
                this.passPhraseWaitInterval = setInterval(()=>{
                    if(!this.getPassphraseModal){
                        clearInterval(this.passPhraseWaitInterval);
                        setTimeout(()=>{
                            $('#twoFA').modal('show');
                        }, 1000)
                    }
                }, 1000)
            }else{
                setTimeout(()=>{
                    $('#twoFA').modal('show');
                }, 2000)
            }
        }

        // FIXME: commented due to in use reason
        // new Splide('.carousel-small', this.splide_options1).mount();
    },
    components: {
        StockListWidget,
        // withdrawAsset,
        sendAsset,
        swapAsset,
        depositAsset,
        assetListUi: assetListUI,
        cards,
        twoFa,
        passphraseInfo
    }
}
</script>

<style scoped>

</style>