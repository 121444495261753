<template>
    <div class="modal fade modalbox" id="cryptoInfo" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{$globalFunc.cryptoList[cryptoId]}}
                    </h5>
                    <a href="javascript:void(0);" data-bs-dismiss="modal">
                        <ion-icon name="close-outline"></ion-icon>
                    </a>
                </div>
                <div class="modal-body px-0 pt-0">
                    <div id="asset-chart">
                        <div id="mtw-chart-loader" class="text-center">
                            <img src="../../../assets/img/metric_logo_bw.png" alt="mtw logo">
                        </div>
                      <CryptoWidget :crypto-id="cryptoId" :fiat-id="fiatId" @load="fadeOutMWOverlay"/>
<!--                        <iframe @load="fadeOutMWOverlay" frameborder="0" scrolling="no" height="270px" width="100%" sandbox="allow-same-origin allow-scripts allow-top-navigation allow-popups" :src="`https://widget.nomics.com/assets/${cryptoId}/${fiatId}/`"></iframe>-->
                    </div>
                    <div class="col-12 pt-2 text-center">
                        <div class="row">
                            <div class="col-6 px-1">
                                <button @click.prevent="toggleReceive" class="btn btn-outline-primary shadowed btn-block">Receive</button>
                            </div>
                            <div class="col-6 px-1">
                                <button @click.prevent="toggleSend" class="btn btn-primary shadowed btn-block">Send</button>
                            </div>
                            <div class="col-12 px-1 pt-2" v-if="auto_trade_loaded">
                                <template v-if="crypto_asset_auto_trade">
                                    <div class="alert alert-outline-primary">
                                        <ion-icon name="information-circle-outline"></ion-icon> Auto Staking is enabled.
                                        <a href="javascript:void(0)" @click="toggleEarnInfo">view</a>
                                    </div>
                                </template>
                                <template v-else>
                                    <button @click="toggleTrade" class="btn btn-outline-info shadowed btn-block">Enable Auto Stake</button>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <a @click="toggleExchange" href="javascript:void(0)" class="exchg row mt-2">
                            <div class="col-8 pt-2">
                                <h4 class="text-white">CONVERT</h4>
                                <p>
                                    Quickly swap one crypto for another
                                </p>
                            </div>
                            <div class="col-4 pt-2">
                                <img src="../../../assets/img/tokens.png" alt="tokens" style="width: 80px;height: 80px">
                            </div>
                        </a>
                        <div class="row">
                            <div class="col-12 pt-2">
                                <h4 class="text-primary">
                                    Transactions
                                    <span class="float-end">
                                        <button @click.prevent="context='received'" class="chip chip-outline border-primary me-1">
                                            <span class="chip-label">
                                                <ion-icon name="arrow-down-outline"></ion-icon> deposit
                                            </span>
                                        </button>
                                        <button @click.prevent="context='sent'" class="chip chip-outline border-primary">
                                            <span class="chip-label">
                                                <ion-icon name="arrow-up-outline"></ion-icon> withdrawal
                                            </span>
                                        </button>
                                        <!--                                        <button @click.prevent="context='swap'" class="chip chip-outline border-primary">-->
                                        <!--                                            <span class="chip-label">-->
                                        <!--                                                <ion-icon name="swap-vertical-outline"></ion-icon>-->
                                        <!--                                            </span>-->
                                        <!--                                        </button>-->
                                    </span>
                                </h4>
                            </div>
                            <div class="col-12 pt-0 transc">
                                <div v-if="context==='received'">
                                    <template v-if="getReceivedPayments.length <= 0">
                                        <div class="col-12 pt-5 text-center">
                                            No received history
                                        </div>
                                    </template>
                                    <template v-else>
                                        <!-- timeline -->
                                        <div class="timeline timed ms-1 me-2">
                                            <div class="item" v-for="(payment, idx) in getReceivedPayments" :key="'received_'+idx">
                                                <span class="time">{{getReadableDatetime(payment.data.created_at)}}</span>
                                                <div class="dot" :class="[
                                            payment.data.api_response_status==='PENDING'?'bg-warning':'',
                                            payment.data.api_response_status==='FAILED'?'bg-danger':'',
                                            payment.data.api_response_status==='CONFIRMED'?'bg-success':'',
                                        ]"></div>
                                                <div class="content">
                                                    <h4 class="title text-lowercase">{{payment.data.api_response_status}}</h4>
                                                    <div class="text">
                                                        Deposit of {{getFiatSymbol(payment.data.currency)}}{{payment.data.currency_value}}
                                                        to {{payment.data.address}} [{{payment.data.crypto}}]
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- * timeline -->
                                    </template>
                                </div>
                                <div v-else-if="context==='sent'">
                                    <template v-if="getSentPayments.length <= 0">
                                        <div class="col-12 pt-5 text-center">
                                            No sent history
                                        </div>
                                    </template>
                                    <template v-else>
                                        <!-- timeline -->
                                        <div class="timeline timed ms-1 me-2">
                                            <div class="item" v-for="(payment, idx) in getSentPayments" :key="'sent_'+idx">
                                                <span class="time">{{getReadableDatetime(payment.data.created_at)}}</span>
                                                <div class="dot bg-primary" :class="[
                                            payment.data.status==='PENDING'?'bg-warning':'',
                                            payment.data.status==='DECLINED'?'bg-danger':'',
                                            payment.data.status==='CONFIRMED'?'bg-success':'',
                                        ]"></div>
                                                <div class="content">
                                                    <h4 class="title text-lowercase">
                                                        {{payment.data.status}}
                                                    </h4>
                                                    <div class="text">
                                                        payment of {{payment.data.crypto_value}} {{payment.data.crypto}}
                                                        <!--                                                Payment of {{getFiatSymbol(payment.data.currency)}}{{payment.data.currency_value}}-->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- * timeline -->
                                    </template>
                                </div>
                                <div v-else>
                                    <template v-if="getSwapPayments.length <= 0">
                                        <div class="col-12 pt-5 text-center">
                                            No conversion history
                                        </div>
                                    </template>
                                    <template v-else>
                                        <!-- timeline -->
                                        <div class="timeline timed ms-1 me-2">
                                            <div class="item" v-for="(payment, idx) in getSwapPayments" :key="'swap_'+idx">
                                                <span class="time">{{getReadableDatetime(payment.data.created_at)}}</span>
                                                <div class="dot bg-success"></div>
                                                <div class="content">
                                                    <h4 class="title text-lowercase">
                                                        {{payment.data.context}}
                                                    </h4>
                                                    <div class="text text-success">
                                                        {{payment.data.description}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- * timeline -->
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import methodsMixin from "../../../utils/methodsMixin";
import {mapGetters} from "vuex";
import CryptoWidget from "../../CryptoWidget.vue";

export default {
    name: "assetDetailsUi",
  components: {CryptoWidget},
    data(){
        return {
            fiatId: this.$store.getters['user/getUser'].data.currency,
            getReceivedPayments: [],
            getSentPayments: [],
            getSwapPayments: [],
            context: 'received',
            crypto_asset_auto_trade: false,
            auto_trade_crypto_obj: {},
            auto_trade_loaded : false,
            semaphore: 1,
        }
    },
    watch: {
        cryptoId(newVal){
            window.sessionStorage.setItem('cryptoContext', newVal)
        }
    },
    computed: {
      ...mapGetters('user', ['getUserFiatSymbol'])
    },
    methods: {
        toggleExchange(){
            $('#cryptoInfo').modal('hide');
            $('#exchangeActionSheet').modal('show')
        },
        toggleReceive(){
            $('#cryptoInfo').modal('hide');
            $('#depositActionSheet2').modal('show')
        },
        toggleSend(){
            $('#cryptoInfo').modal('hide');
            $('#sendActionSheet2').modal('show')
        },
        toggleTrade(){
            $('#cryptoInfo').modal('hide');
            this.$router.push({name: 'AutoStake', params: {cryptoId: this.cryptoId}})
        },
        toggleEarnInfo(){
            $('#cryptoInfo').modal('hide');
            this.$router.push({name: 'AutoStakeInfo', params: {cryptoId: this.cryptoId}})
        },
        async fetchTransactions(){
            this.context = 'received';
            let response = await this.$store.dispatch('transaction/fetchReceivedPaymentsFiltered', this.cryptoId);
            this.getReceivedPayments = response.data;
            let response1 = await this.$store.dispatch('transaction/fetchSentPaymentsFiltered', this.cryptoId);
            this.getSentPayments = response1.data;
        },
        resetTransactions(){
            this.getReceivedPayments = [];
            this.getSentPayments = [];
            this.getSwapPayments = [];
        },
        async fetchAutoTradeStatus(){
            // Init with default value
            if(this.$store.getters['user/getUser'].data.auto_trade_assets){
                if(this.$store.getters['user/getUser'].data.auto_trade_assets[this.cryptoId]){
                    this.auto_trade_crypto_obj = this.$store.getters['user/getUser'].data.auto_trade_assets[this.cryptoId];
                    this.crypto_asset_auto_trade = this.$store.getters['user/getUser'].data.auto_trade_assets[this.cryptoId].enabled;
                }
            }
            this.auto_trade_loaded = true;

            let response = await this.$store.dispatch('user/getUser', this.$store.getters['user/getUser'].id);
            if(response.status){
                let auto_trade_obj = response.data.data.auto_trade_assets;
                if(auto_trade_obj){
                    if(auto_trade_obj[this.cryptoId]){
                        this.crypto_asset_auto_trade = auto_trade_obj[this.cryptoId].enabled;
                    }
                }
            }
        },
        fadeOutMWOverlay(){
            $('#mtw-chart-loader').fadeOut('slow')
        }
    },
    mixins: [methodsMixin],
    props: {
        cryptoId: {
            default: ''
        },
    },
    mounted() {
        const modal = $('#cryptoInfo');
        modal.on('show.bs.modal', ()=>{
            setTimeout(()=>{
                this.fetchAutoTradeStatus();
                this.fetchTransactions();
            }, 1500);
        })
        modal.on('hide.bs.modal', ()=>{
            $('#mtw-chart-loader').fadeIn('slow');
            this.resetTransactions();
            this.crypt_fiat_equiv = 0;
            this.crypto_asset_auto_trade = false;
            this.auto_trade_crypto_obj = {}
            this.auto_trade_loaded = false;
            this.withdraw_amount = 0;
            this.withdraw_enabled = false;
        })
    }

}
</script>

<style scoped>
#asset-chart{
    position: relative;
    height: 270px;
    max-height: 270px;
    min-height: 270px;
}
#mtw-chart-loader{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(250,250,250);
    padding-top: 20%
}
#mtw-chart-loader img{
    width: 100px;
    height: 40px
}
.exchg{
    color: #ffffff;
    background: rgb(98,54,255);
    background: linear-gradient(90deg, rgba(98,54,255,1) 0%, rgba(255,255,255,1) 100%);
}
.transc{
    min-height: 100px;
}
</style>