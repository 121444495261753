<template>
  <div class="tradingview-widget-container__widget"></div>
</template>

<script>
export default {
  name: "CryptoWidget",
  props: {
    cryptoId: {required: true, type: String},
    fiatId: {default: 'USD', type: String},
  },
  watch: {
    cryptoId: function (newVal){
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js";
      script.innerHTML = JSON.stringify({
        symbol: `FX:${newVal}${this.fiatId}`,
        width: "100%",
        height: "100%",
        locale: "en",
        dateRange: "1D",
        colorTheme: "light",
        isTransparent: false,
        autosize: true,
        largeChartUrl: "https://earnrelai.com/.com",
        noTimeScale: false
      });
      script.onload = ()=>{
        this.$emit('load')
      }
      const item = document.querySelector(".tradingview-widget-container__widget");
      item.innerHTML = "";
      item.appendChild(script);
    }
  },
}
</script>

<style scoped>
.tradingview-widget-container__widget{
  height: 270px;
}
</style>