<template>
    <div class="modal fade action-sheet" data-bs-backdrop="static" id="cardApplicationForm" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        KYC Verification
                        <a class="float-end" href="javascript:void(0)" data-bs-dismiss="modal">
                            <ion-icon name="close"></ion-icon>
                        </a>
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content" v-if="isQualified">
                        <div class="card">
                            <div class="card-body px-0">
                                <ul class="nav nav-tabs style1" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" data-bs-toggle="tab" href="#profile" role="tab" ref="profile">
                                            <ion-icon name="person-circle-outline"></ion-icon> Profile
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#idcard" role="tab" ref="idcard">
                                            <ion-icon name="card-outline"></ion-icon> ID Card
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#vcard" role="tab" ref="vcard">
                                            <ion-icon name="id-card-outline"></ion-icon> Selfie
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#settings" role="tab" ref="settings">
                                            <ion-icon name="browsers-outline"></ion-icon> Finish up
                                        </a>
                                    </li>
                                </ul>
                                <div class="tab-content mt-1">
                                    <div class="tab-pane fade show active" id="profile" role="tabpanel">
                                        <div >
                                            <div class="form-group basic">
                                                <div class="input-wrapper">
                                                    <label class="label" for="email">Email</label>
                                                    <input type="email" :value="user.data.email" id="email" class="form-control" placeholder="email" disabled>
                                                </div>
                                            </div>
                                            <div class="form-group basic">
                                                <div class="input-wrapper">
                                                    <label class="label" for="phone">Phone</label>
                                                    <input type="tel" :value="user.data.phone" id="phone" class="form-control" placeholder="telephone" disabled>
                                                    <small v-if="!user.data.phone" class="form-text text-warning">
                                                        please update your phone number from the settings page
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="form-group basic">
                                                <div class="input-wrapper">
                                                    <label class="label" for="address">Home Address</label>
                                                    <small class="text-primary">
                                                        This is where we will deliver your card once approved.
                                                    </small>
                                                    <input type="tel" :value="user.data.address" id="address" class="form-control" placeholder="No Address Specified" disabled>
                                                    <small v-if="!user.data.address" class="form-text text-warning">
                                                        please update your home address from the settings page
                                                    </small>
                                                </div>
                                            </div>

                                            <div class="form-group basic">
                                                <button type="button" class="btn btn-primary btn-block" @click="nextTab('idcard')">
                                                    Next <ion-icon name="chevron-forward-outline"></ion-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="idcard" role="tabpanel">
                                        <div class="container-fluid">
                                            <div class="card">
                                                <div class="card-header" style="text-align: justify">
                                                    <small class="text-primary">
                                                        You are required to upload a clear front and back image of your
                                                        government issued identity card
                                                    </small>
                                                </div>
                                                <div class="card-body">
                                                    <div>
                                                        <div class="col-12 text-center">
                                                            <h4>Front View</h4>
                                                        </div>
                                                        <div class="row justify-content-center" v-if="cardForm.gCard.front">
                                                            <div class="col-10 front">
                                                                <img id="gcard-front" src="" alt="id front image" class="imaged img-fluid">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 text-center mt-3">
                                                            <label for="front-view" class="btn btn-outline-primary">
                                                                <ion-icon name="camera-outline"></ion-icon> upload image
                                                                <input @change="uploadImage($event, 'gcard')" id="front-view" type="file" accept="image/*">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="mt-5">
                                                        <div class="col-12 text-center">
                                                            <h4>Back View</h4>
                                                        </div>
                                                        <div class="row justify-content-center" v-if="cardForm.gCard.back">
                                                            <div class="col-10 front">
                                                                <img id="gcard-back" src="" alt="id back image" class="imaged img-fluid">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 text-center mt-3">
                                                            <label for="back-view" class="btn btn-outline-primary">
                                                                <ion-icon name="camera-outline"></ion-icon> upload image
                                                                <input @change="uploadImage($event, 'gcard', 'back')" id="back-view" type="file" accept="image/*">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="form-group basic mt-3">
                                                        <button type="button" class="btn btn-primary btn-block" @click="nextTab('vcard')">
                                                            Next <ion-icon name="chevron-forward-outline"></ion-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="vcard" role="tabpanel">
                                        <div class="container-fluid">
                                            <div class="card">
                                                <div class="card-header">
                                                    <small class="text-primary">
                                                        Upload a clear selfie of you holding your valid government issued identity card
                                                    </small>
                                                </div>
                                                <div class="card-body">
                                                    <div>
<!--                                                        <div class="col-12 text-center">-->
<!--                                                            <h4>Front View</h4>-->
<!--                                                        </div>-->
                                                        <div class="row justify-content-center" v-if="cardForm.vCard.front">
                                                            <div class="col-10 front">
                                                                <img id="vcard-front" src="" alt="id image" class="imaged img-fluid">
                                                            </div>
                                                        </div>
                                                        <div class="row justify-content-center" v-else>
                                                            <div class="col-10 text-center front">
                                                                <img src="../../assets/img/selfie.png" alt="id image" class="img-fluid">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 text-center mt-3">
                                                            <label for="v-front-view" class="btn btn-outline-primary">
                                                                <ion-icon name="camera-outline"></ion-icon> upload image
                                                                <input @change="uploadImage($event, 'vcard')" id="v-front-view" type="file" accept="image/*">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="form-group basic mt-3">
                                                        <button type="button" class="btn btn-primary btn-block" @click="nextTab('settings')">
                                                            Finish up <ion-icon name="chevron-forward-outline"></ion-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="settings" role="tabpanel">
                                        <div class="form-group basic">
                                            <small class="text-info">
                                                To meet with the demands of the Internal Revenue Service (IRS), you are required
                                                to provide your SSN
                                            </small>
                                            <div class="input-wrapper mt-3">
                                                <label class="label" for="ssn">Social Security Number</label>
                                                <input type="text" minlength="9" maxlength="9" v-model.number="cardForm.ssn" id="ssn" class="form-control" placeholder="123456789">
                                            </div>
                                        </div>
                                        <div class="form-group basic mt-3">
                                            <button type="button" class="btn btn-success btn-block" @click="validateForm">
                                                Submit&nbsp;<ion-icon name="checkmark-done-outline"></ion-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {CardApplicationModel} from '../../models/cardApplication';
import {HWApplicationModel} from '../../models/hwApplication';

export default {
    name: 'cardApplicationForm',
    data(){
        return {
            cardForm: new CardApplicationModel()
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/getUser'
        })
    },
    methods: {
        nextTab(elem){
            this.$refs[elem].click();
        },
        uploadImage(event,type, context='front'){
            const file = event.target.files[0];
            const file_ext = file.name.split('.').pop();
            if($.inArray(file_ext, ['png', 'jpg', 'jpeg']) >= 0){
                const reader = new FileReader();
                reader.onload = (e)=>{
                    switch (type){
                        case 'gcard': {
                            if(context === 'front'){
                                this.cardForm.gCard.front = file;
                                setTimeout(()=>{
                                    $('#gcard-front').attr('src', e.target.result)
                                }, 700)
                            }else{
                                this.cardForm.gCard.back = file;
                                setTimeout(()=>{
                                    $('#gcard-back').attr('src', e.target.result)
                                }, 700)
                            }
                            break;
                        }
                        case 'vcard': {
                            if(context === 'front'){
                                this.cardForm.vCard.front = file;
                                setTimeout(()=>{
                                    $('#vcard-front').attr('src', e.target.result)
                                }, 700)
                            }else{
                                this.cardForm.gCard.back = file;
                                setTimeout(()=>{
                                    $('#vcard-back').attr('src', e.target.result)
                                }, 700)
                            }
                            break;
                        }
                    }
                }
                reader.readAsDataURL(file);
            }else{
                return this.$store.dispatch('notification/display_noti', {
                    message: 'You uploaded an invalid image type',
                    timeout: 2000
                })
            }
        },
        validateForm(){
            if(!this.cardForm.gCard.front || !this.cardForm.gCard.back){
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Upload the front and back photo of your government issued ID Card',
                    timeout: 1500
                })
            }else if(!this.cardForm.vCard.front){
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Upload a selfie while holding your voters card',
                    timeout: 1500
                })
            }else if(!this.cardForm.ssn){
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Fill in your Social Security Number',
                    timeout: 1500
                })
            }else if(typeof this.cardForm.ssn !== 'number'){
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Enter a valid Social Security Number',
                    timeout: 1500
                })
            }else if(String(this.cardForm.ssn).length !== 9){
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Enter a valid Social Security Number',
                    timeout: 1500
                })
            }else {
                return this.submitForm()
            }
        },
        async submitForm(){
            this.$loader.show();
            let response;
            if(this.context === 'CARD'){
                response = await this.$store.dispatch('card/addNewApplication', this.cardForm);
            }else{
                response = await this.$store.dispatch('HWToken/addNewApplication', this.cardForm);
            }
            this.$loader.hide();
            if(response.status){
                $('#cardApplicationForm').modal('hide');
                this.resetForm();
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Your application have been sent for processing'
                })
            }else{
                return this.$store.dispatch('notification/display_noti', {
                    message: response.message
                })
            }
        },
        resetForm(){
            if(this.context === 'CARD'){
                this.cardForm = new CardApplicationModel()
            }else if(this.context === 'TOKEN') {
                this.cardForm = new HWApplicationModel()
            }
            $('#front-view').val("");
            $('#back-view').val("");
            $('#v-front-view').val("");
            $('#gcard-front').attr('src', '');
            $('#gcard-back').attr('src', '');
            $('#vcard-front').attr('src', '');
        }
    },
    props: {
        isQualified: {
            type: Boolean,
            default: false
        },
        context: {
            type: String,
            default: "CARD",
        }
    },
    mounted() {
        if(this.context === 'CARD'){
            this.cardForm = new CardApplicationModel()
        }else if(this.context === 'TOKEN') {
            this.cardForm = new HWApplicationModel()
        }
        this.cardForm.user = this.user.id
    }
};
</script>

<style scoped>
    .front{
        height: 150px;
    }
    .front img{
        height: inherit;
    }
    input[type='file']{
        visibility: hidden;
        position: absolute;
    }
</style>