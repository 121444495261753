<template>
    <div class="splash-page mt-2 mb-3">
        <p>
            Copy the address or scan the QR code below <br>
        </p>
        <p class="text-dark fw-bold mb-0">
            <small>{{address}}</small>
        </p>
        <div>
            <ion-icon @click="copyAddress" class="text-primary mt-2 mr-2" style="font-size: 20px" name="copy-outline"></ion-icon>
        </div>
        <div class="mb-1">
            <qr-code :value="cryptoID==='BTC'?'bitcoin:'+address:address" :scale="5"/>
        </div>
        <h2 class="mb-2">Scan the QR Code</h2>
        <p>
            This transaction will expire in the next <strong>1 hour</strong>
        </p>
        <p>
            Send only {{cryptoID}} {{$globalFunc.cryptoList[cryptoID]||''}} to this address. <br>
            Sending any other asset will result in the loss  of your deposit.
        </p>
    </div>
</template>

<script>
export default {
    name: "completeDepositAsset",
    props: {
        address: {
            type: String,
            default: "metrics-wallet"
        },
        cryptoID: {
            type: String
        }
    },
    methods: {
        copyAddress(){
            navigator.clipboard.writeText(this.address);
            return this.$store.dispatch('notification/display_noti', {
                message: 'Address copied!',
                timeout: 1000
            });
        }
    }
}
</script>

<style scoped>

</style>