<template>
  <div class="tradingview-widget-container__widget stocks"></div>
</template>

<script>
export default {
  name: "StockListWidget",
  mounted() {
    this.initStocks();
  },
  methods: {
    initStocks(){
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-hotlists.js";
      script.innerHTML = JSON.stringify({
        "colorTheme": "light",
        "dateRange": "1D",
        "exchange": "US",
        "showChart": true,
        "locale": "en",
        "width": "100%",
        "height": "100%",
        "largeChartUrl": "",
        "isTransparent": false,
        "showSymbolLogo": false,
        "showFloatingTooltip": false,
        "plotLineColorGrowing": "rgba(41, 98, 255, 1)",
        "plotLineColorFalling": "rgba(41, 98, 255, 1)",
        "gridLineColor": "rgba(42, 46, 57, 0)",
        "scaleFontColor": "rgba(19, 23, 34, 1)",
        "belowLineFillColorGrowing": "rgba(41, 98, 255, 0.12)",
        "belowLineFillColorFalling": "rgba(41, 98, 255, 0.12)",
        "belowLineFillColorGrowingBottom": "rgba(41, 98, 255, 0)",
        "belowLineFillColorFallingBottom": "rgba(41, 98, 255, 0)",
        "symbolActiveColor": "rgba(41, 98, 255, 0.12)"
      });
      script.onload = ()=>{
        this.$emit('load')
      }
      const item = document.querySelector(".tradingview-widget-container__widget.stocks");
      item.innerHTML = "";
      item.appendChild(script);
    }
  }
}
</script>

<style scoped>
.tradingview-widget-container__widget.stocks{
  height: 500px;
}
</style>